/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    blockquote: "blockquote",
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a",
    code: "code",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "What is HomeBrew"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "The missing package manager for macOS (or Linux)"), "\n"), "\n", React.createElement(_components.p, null, "HomeBrew is or commonly called Brew is an open-source package manger that enables easy managment of Apps and software all from the CLI."), "\n", React.createElement(_components.h2, null, "Why use HomeBrew"), "\n", React.createElement(_components.h3, null, "Software and Package Management"), "\n", React.createElement(_components.p, null, "Homebrew centralized and makes the management of packages and apps faster and more straight forward. Software can be installed directly from the CLI rather than going to a download page."), "\n", React.createElement(_components.h3, null, "Management of Multiple Versions"), "\n", React.createElement(_components.p, null, "HomeBrew simplifies handling multiple versions of languages such as Node and Python."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "src/content/articles/solutions-for-managing-multiple-versions-of-node-on-macos.md"
  }, "Solutions for manging multiple version of node on macOS")), "\n"), "\n", React.createElement(_components.h2, null, "Bulk Management With ", React.createElement(_components.code, null, ".Brewfile"), " or ", React.createElement(_components.code, null, ".brewfile")), "\n", React.createElement(_components.h3, null, "Backup Current Packages and Casks"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://gist.github.com/ChristopherA/a579274536aab36ea9966f301ff14f3f"
  }, "Gits ref for Brewfile")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "brew bundle dump\n")), "\n", React.createElement(_components.p, null, "Create a ", React.createElement(_components.code, null, "Brewfile"), " with a list of all current packages. To install packages from the ", React.createElement(_components.code, null, "Brewfile"), " run ", React.createElement(_components.code, null, "brew bundle")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "brew bundle --file=~<path>/Brewfile\n")), "\n", React.createElement(_components.h2, null, "CheatSheet"), "\n", React.createElement(_components.h3, null, "Install"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "/bin/bash -c \"$(curl -fsSL https://raw.githubusercontent.com/Homebrew/install/HEAD/install.sh)\"\n")), "\n", React.createElement(_components.p, null, "Get info on a given formula"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "version"), "\n", React.createElement(_components.li, null, "description"), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "brew info wget\n")), "\n", React.createElement(_components.p, null, "List currently installed packages"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "brew list\n")), "\n", React.createElement(_components.p, null, "Search for package"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "brew search <package-name>\n")), "\n", React.createElement(_components.p, null, "Install package or cask"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "brew install <package-name>\n")), "\n", React.createElement(_components.p, null, "Install ", React.createElement(_components.code, null, "cask")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "brew install. --cask <cask-name>\n")), "\n", React.createElement(_components.p, null, "uninstall package"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "brew uninstall <package-name>\n")), "\n", React.createElement(_components.h2, null, "uninstall"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "/bin/bash -c \"$(curl -fsSL https://raw.githubusercontent.com/Homebrew/install/HEAD/uninstall.sh)\"\n")), "\n", React.createElement(_components.h2, null, "Reference"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://tomlankhorst.nl/brew-bundle-restore-backup/"
  }, "Brew Bundle Restore and Backup")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
